<template>
  <div class="header">
    <div class="search-bar" v-if="showSearch">
      <div class="icon-display icon-arrow-full-outline-left cursor-pointer" @click="handleBack" />
      <SearchBarField
        @changedInput="handlerFilterInput($event)"
        :placeholder="config[lang].placeholder"
        :auto-focus="true"
      />
    </div>

    <template v-else>
      <div class="header-text">
        <div class="icon-display icon-arrow-full-outline-left cursor-pointer" @click="goBack" />
        <div class="heading" v-text="config[lang].title" />
        <div class="icon-display icon-search-off cursor-pointer" @click="openSearch" />
      </div>
      <FilterCarrousel
        :title="config[lang].filter"
        :options="filterOptions"
        :selection="filterQuery"
        :loading="filterLoading"
        :language="lang"
        @confirm="handleFilterConfirmSelection"
      />
    </template>

  </div>
</template>

<script>
import { FilterCarrousel, SearchBarField } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
export default {
  name: 'Filters',
  components: { FilterCarrousel, SearchBarField },
  props: {
    lang: String,
    country: String
  },
  data () {
    return {
      searchTimeOut: null,
      showSearch: false,
      inputValue: null,
      config: {
        es: {
          title: 'Especialistas',
          filter: 'Filtrar por',
          placeholder: 'Buscar por nombre'
        },
        en: {
          title: 'Specialists',
          filter: 'Filter by',
          placeholder: 'Search by name'

        },
        pt: {
          title: 'Especialistas',
          filter: 'Filtrar por',
          placeholder: 'Busca pelo nome'

        }
      }
    }
  },
  async created () {
    await this.storeDispatch()
  },
  methods: {
    async storeDispatch () {
      await this.$store.dispatch('filters/setCountry', this.country)
      await this.$store.dispatch('filters/setLanguage', this.lang)

      await this.$store.dispatch('filters/init', { cleanedRequest: true, internal: true })
    },
    handleFilterConfirmSelection (selection) {
      this.$store.commit('filters/SET_LOADING', {
        key: 'results',
        value: true
      })

      this.$store.dispatch(
        'filters/setQuery',
        { values: selection, cleanedRequest: true, internal: true }
      )
    },
    handlerFilterInput (value) {
      clearTimeout(this.searchTimeOut)
      this.searchTimeOut = setTimeout(() => {
        if (
          value.length === 0 ||
          value.match(/^ *$/) !== null
        ) {
          return
        }
        window.parent.postMessage(
          {
            action: 'searchBar',
            data: { value }
          },
          '*'
        )
      }, 800)
    },
    handleBack () {
      this.showSearch = false
      window.parent.postMessage(
        {
          action: 'refresh'
        },
        '*'
      )
    },
    goBack () {
      const url = new URL(window.location)
      const searchParams = new URLSearchParams(url.search)
      const backValue = searchParams.get('back')
      window.parent.postMessage(
        {
          action: 'goHome',
          data: backValue
        },
        '*'
      )
    },
    openSearch () {
      this.showSearch = true
      window.parent.postMessage(
        {
          action: 'openSearch'
        },
        '*'
      )
    }
  },
  computed: {
    ...mapGetters({
      filterLoading: 'filters/loading',
      filterOptions: 'filters/options',
      filterQuery: 'filters/query'
    })
  }
}
</script>

<style lang="sass" scoped>
.header
  position: relative
  z-index: 100
  width: 100%
  border-bottom: 1px solid var(--gray-20)
  background: var(--white)
  padding: env(safe-area-inset-top) 16px 0
  &-text
    display: grid
    grid-template-columns: 30px 1fr 30px
    gap: 16px
    align-items: center
    height: 52px
    justify-content: space-between
    max-width: 1040px
    margin: 0 auto
.search-bar
  display: grid
  grid-template-columns: 40px 1fr
  padding: 10px 16px
  align-items: center
  justify-content: center

::v-deep
  .checkmark::after
    width: 4px !important
    height: 9px !important
    border-width: 0 3px 3px 0
</style>
